import { useLazyQuery } from "@apollo/client";
import { TRACK_ADD_LISTING } from "../../utils/trackEvent";
import { TOKEN_REDIRECT_PANEL } from "../../action/user";
import { useEffect, useState } from "react";
import Image from "next/image";
import { Carousel } from "react-bootstrap";

export default function BannerSlider() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [getToken] = useLazyQuery(TOKEN_REDIRECT_PANEL, {
    onCompleted: (resp) => {
      window.open(
        `${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/masuk/${resp.token_redirect_panel}`
      );
    },
    onError: (resp) => {
      if (resp.message === "Unauthenticated") {
        window.open(`${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/listing-saya`);
      }
    },
  });

  function onAddListingClick() {
    TRACK_ADD_LISTING();
    getToken();
  }

  return (
    <div id="pasangiklan" className="position-relative">
      <div
        className="position-absolute"
        style={{
          width: "187px",
          height: "181px",
          top: -120,
          left: -10,
          zIndex: -1,
        }}
      >
        <Image
          src="/assets/banner-slider-background.png"
          layout="fill"
          alt="banner item"
        />
      </div>

      <div className="position-relative container d-flex flex-column align-items-center gap-2 my-5">
        <div className="w-100">
          <Carousel
            activeIndex={activeIndex}
            touch={true}
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              <div className="align-content-center position-relative card-banner-slider">
                <Image
                  src="/assets/bg_add_listing.png"
                  alt="listing recommendation"
                  placeholder="blur"
                  blurDataURL="/assets/bg_add_listing.png"
                  className="card"
                  layout="fill"
                />

                <div className="position-relative card-body align-content-center p-3 m-3">
                  <div>
                    <h2 className="mb-4 text-white">
                      Punya Properti untuk Dijual/Disewakan?
                    </h2>
                    <button
                      onClick={onAddListingClick}
                      className="d-none d-md-block btn btn-warning text-white py-2 px-5 text-decoration-none"
                      target="_blank"
                    >
                      Pasang Listing Anda
                    </button>

                    <button
                      onClick={onAddListingClick}
                      className="d-md-none w-100 btn btn-warning text-white py-2 px-5 text-decoration-none"
                      target="_blank"
                    >
                      Pasang Listing Anda
                    </button>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* <div className="d-flex gap-2">
          <SliderDots
            active={activeIndex === 0}
            onClick={() => setActiveIndex(0)}
          />
        </div> */}
      </div>
    </div>
  );
}

const SliderDots = ({ active, onClick }) => {
  return (
    <div
      className={`${
        active ? "bg-primary" : "bg-secondary"
      } cursor-pointer rounded-2`}
      style={{ width: "3.125rem", height: "0.625rem" }}
      onClick={onClick}
    ></div>
  );
};
