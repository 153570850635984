import Head from "next/head";
import UserBaseRecommendation from "../component/UserBaseRecommendation";
import CategoryList from "../component/CategoryList";
import AddListingRecommendation from "../component/AddListingRecommendation";
import { GET_LISTING_RECOMMENDATION } from "../action/listingRecommendation";
import { useQuery } from "@apollo/client";
import PackageSection from "../component/PackageSection";
import { useEffect } from "react";
import { useStateContext } from "../context";
import ModalAds from "../component/ModalAds";
import { useCookie } from "next-cookie";
import FooterAppAds from "../component/common/FooterAppAds";
import Categories from "../component/CategoryList/Categories";
import BlogSection from "../component/common/BlogSection";
import Footer from "../component/common/Footer";
import { initializeApollo } from "../apollo-client";
import HeroSection from "../component/Home/HeroSection";
import BannerSlider from "../component/BannerSlider";

export default function Home(props) {
  // const distinctId = props.distinctId;
  const { locations, commons, recommendations, latests, userBased, posts } =
    props;
  const cookies = useCookie();

  const [state, setState] = useStateContext();
  const clickCount = state.countView;

  // const { loading, error, data } = useQuery(GET_LISTING_RECOMMENDATION, {
  //   variables: {
  //     page: 1,
  //     distinct_id: distinctId,
  //     hasDistinctId: !!distinctId,
  //   },
  // });

  // const locations =
  //   data?.recommendation?.user_location_based_recommendation?.nodes || [];
  // const commons =
  //   data?.recommendation?.common_listing_recommendation_for_user || [];
  // const recommendations = data?.recommendation?.listings?.nodes || [];
  // const latests = data?.recommendation?.latest_items || [];
  // const userBased =
  //   data?.recommendation?.user_location_based_recommendation?.nodes || [];
  // const posts = data?.blog?.posts || [];

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (loc) => {
          cookies.set("lat", loc.coords.latitude);
          cookies.set("lng", loc.coords.longitude);
        },
        (error) => {
          if (error.code === 1) {
            cookies.remove("lat");
            cookies.remove("lng");
          }
        }
      );
    }
  }, []);

  return (
    <>
      <Head>
        <title>Jual beli properti online di Indonesia | Rumahdewi.com</title>
        <meta
          name="description"
          content="Situs jual beli properti online berbasis peta tercepat di Indonesia. Cari informasi seputar rumah, apartemen, tanah, gudang dan properti lainnya di seluruh Indonesia hanya di Rumahdewi.com."
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_URL}`}
          key="canonical"
        />
      </Head>
      <main className="d-flex flex-column gap-5">
        <HeroSection />

        <BannerSlider />

        {locations.length > 0 && (
          <UserBaseRecommendation
            subtitle="Pilihan Terbaik"
            title="Properti berdasarkan Lokasi Anda"
            data={locations}
            distinctId={props.distinctId}
            hideReels
            type="user-location-based"
            clickCount={clickCount}
            setClickCount={() =>
              setState({ ...state, countView: clickCount + 1 })
            }
          />
        )}

        {/* <PackageSection /> */}

        {commons.length > 0 && (
          <UserBaseRecommendation
            subtitle="Beberapa Pilihan"
            title="Rekomendasi Properti untuk Anda"
            data={commons}
            distinctId={props.distinctId}
            hideReels
            type="user-common-listing"
            clickCount={clickCount}
            setClickCount={() =>
              setState({ ...state, countView: clickCount + 1 })
            }
          />
        )}

        {/* <UserBaseRecommendation
            title="Properti berdasarkan Aktivitas Anda"
            data={recommendations}
            distinctId={props.distinctId}
            type="user-activity-based-listing"
            clickCount={clickCount}
            setClickCount={() =>
              setState({ ...state, countView: clickCount + 1 })
            }
          /> */}

        {latests.length > 0 && (
          <UserBaseRecommendation
            title="Properti Terbaru"
            data={latests}
            distinctId={props.distinctId}
            hideReels
            type="latest-listing"
            clickCount={clickCount}
            setClickCount={() =>
              setState({ ...state, countView: clickCount + 1 })
            }
          />
        )}

        {userBased.length > 0 && (
          <UserBaseRecommendation
            title="Pilihan Lainnya Untuk Anda"
            data={userBased}
            distinctId={props.distinctId}
            hideReels
            type="listing-recommendation-user-based"
            clickCount={clickCount}
            setClickCount={() =>
              setState({ ...state, countView: clickCount + 1 })
            }
          />
        )}

        <FooterAppAds />

        <BlogSection data={posts} />

        <ModalAds />

        <Footer />
      </main>
    </>
  );
}

export async function getServerSideProps(ctx) {
  const { req } = ctx;
  const postHogCookie =
    req.cookies["ph_" + process.env.POSTHOG_PROJECT_KEY + "_posthog"];
  let distinctId = null;
  if (postHogCookie) {
    distinctId = JSON.parse(postHogCookie).distinct_id;
  }

  try {
    const client = initializeApollo();

    const { data } = await client.query({
      query: GET_LISTING_RECOMMENDATION,
      variables: {
        page: 1,
        distinct_id: distinctId,
        hasDistinctId: !!distinctId,
      },
    });

    return {
      props: {
        locations:
          data?.recommendation?.user_location_based_recommendation?.nodes,
        commons: data?.recommendation?.common_listing_recommendation_for_user,
        recommendations: data?.recommendation?.listings?.nodes,
        latests: data?.recommendation?.latest_items,
        userBased:
          data?.recommendation?.user_location_based_recommendation?.nodes,
        posts: data?.blog?.posts,
      },
    };
  } catch (error) {
    return {
      props: {
        locations: [],
        commons: [],
        recommendations: [],
        latests: [],
        userBased: [],
        posts: [],
      },
    };
  }
}
