import React, { useEffect, useRef, useState } from "react";
import ListingItem from "../common/ListingItem";
import { getReel } from "../../action/reelRecommendation";
import NoRecommendation from "./NoRecommendation";
import {
  REEL_CLICK,
  TRACK_LISTING_VIEW,
  TRACK_RECOMMENDATION_LISTING_CLICK,
} from "../../utils/trackEvent";
import { sendFeedback } from "../../action/sendFeedback";
import analytic from "../../utils/analytic";
import ListingItemLoading from "../ListingItemLoading";
import { Button, Carousel } from "react-bootstrap";
import Link from "next/link";
import Image from "next/image";
import {
  LeftSliderArrow,
  LeftSliderMobileArrow,
  RightSliderArrow,
  RightSliderMobileArrow,
} from "../../utils";
import Slider from "react-slick";

const UserBaseRecommendation = (props) => {
  const {
    data = [],
    distinctId,
    hideReels = false,
    title,
    type,
    clickCount,
    setClickCount,
  } = props;
  const ref = useRef(null);

  const [reel, setReel] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [mobileActiveIndex, setMobileActiveIndex] = useState(1);
  const settings = {
    slidesToShow: 3.2,
    slidesToScroll: 3,
    initialSlide: 1,
    infinite: false,
    speed: 1000,
    arrows: false,
    afterChange: (current) => {
      setActiveIndex(Math.floor(current % 3));
    },
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          speed: 600,
          afterChange: (current) => {
            setMobileActiveIndex(Math.ceil(current));
          },
        },
      },
    ],
  };

  useEffect(async () => {
    if (distinctId !== null && !hideReels) {
      const {
        data: {
          reels_query: {
            reel: { latest_preferences },
          },
        },
      } = await getReel(distinctId);
      setReel(latest_preferences);
    }
  }, [distinctId, hideReels]);

  const onReelBadgeClick = (row) => {
    REEL_CLICK(row, {
      from: "home",
    });
  };

  const onRecommendationClick = (listingDetail) => {
    TRACK_LISTING_VIEW(listingDetail.id, {
      from: type,
      area: listingDetail.area.area_name,
      district: listingDetail.district.district_name,
      state: listingDetail.state.state_name,
      category: listingDetail.category,
      listingFor: listingDetail.listingFor,
    });

    TRACK_RECOMMENDATION_LISTING_CLICK(listingDetail.id, {
      from: type,
    });

    if (
      ["common", "latest"].some((needTracked) => type.includes(needTracked))
    ) {
      sendFeedback({
        userId: analytic?.get_distinct_id(),
        type: "CLICK",
        id: listingDetail.id,
      });
    }
  };

  const limit = Math.ceil(data.length / 3);
  const groupedData = Array.from({ length: limit }, (_, i) =>
    data.slice(i * 3, i * 3 + 3)
  );

  return (
    <div className="position-relative">
      {type === "user-location-based" && (
        <Image
          src="/assets/city-property-background.png"
          layout="fill"
          className="opacity-25"
          objectFit="cover"
        />
      )}

      <div
        className="d-md-none position-absolute w-100 top-50 d-flex justify-content-between"
        style={{ zIndex: 2 }}
      >
        <button
          className="border-0 bg-transparent"
          disabled={mobileActiveIndex === 0}
          onClick={() => ref?.current?.slickPrev()}
        >
          <RightSliderMobileArrow />
        </button>

        <button
          className="border-0 bg-transparent"
          disabled={mobileActiveIndex === data.length - 1}
          onClick={() => ref?.current?.slickNext()}
        >
          <LeftSliderMobileArrow />
        </button>
      </div>

      <div className="container position-relative">
        <div className="d-flex flex-column gap-4">
          {/* <h2 className="card-title h6 text-warning mb-0">{subtitle}</h2> */}
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2">
              <div
                style={{ background: "#000000", width: "5px", height: "32px" }}
              />
              <h2 className="card-title font-roboto fw-bold">{title}</h2>
            </div>

            <div className="d-none d-md-flex gap-3 align-items-center">
              <button
                className="border-0 bg-transparent"
                disabled={activeIndex === 0}
                onClick={() => ref?.current?.slickPrev()}
              >
                <LeftSliderArrow />
              </button>

              <div className="d-flex gap-2">
                {groupedData.map((_, index) => (
                  <DotSlider
                    isActive={activeIndex === index}
                    onClick={() => {
                      setActiveIndex(index);
                      ref?.current?.slickGoTo(index * 3);
                    }}
                  />
                ))}
              </div>

              <button
                className="border-0 bg-transparent"
                disabled={activeIndex === groupedData.length - 1}
                onClick={() => ref?.current?.slickNext()}
              >
                <RightSliderArrow />
              </button>
            </div>
          </div>
          {/* <hr /> */}

          {reel.length > 0 && (
            <div className={"mb-3"}>
              <div
                className="d-flex"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "auto",
                  maxWidth: "200%",
                }}
              >
                {reel.map(function (row, index) {
                  return (
                    <Link href={`/listing?search=${row}`}>
                      <a
                        style={{ cursor: "pointer" }}
                        key={`badge-${index}`}
                        onClick={() => {
                          onReelBadgeClick(row);
                        }}
                        className="badge rounded-pill bg-secondary me-3 py-3 text-decoration-none"
                      >
                        {row}
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}

          <div className="row g-4">
            {data.length > 0 ? (
              <div className="col-12">
                <Slider ref={ref} {...settings}>
                  {data.map((recommendation) => (
                    <ListingItem
                      {...recommendation}
                      photoHeight="150px"
                      onClick={onRecommendationClick}
                      clickCount={clickCount}
                      setClickCount={setClickCount}
                    />
                  ))}
                </Slider>
                {/* <Carousel
                  className="d-none d-md-block"
                  activeIndex={activeIndex}
                  controls={false}
                  indicators={false}
                >
                  {groupedData.map((group) => (
                    <Carousel.Item>
                      <div className="d-flex gap-2 flex-nowrap overflow-hidden">
                        {group.map((recommendation) => (
                          <div className="col-4" key={recommendation.id}>
                            <ListingItem
                              {...recommendation}
                              photoHeight="150px"
                              onClick={onRecommendationClick}
                              clickCount={clickCount}
                              setClickCount={setClickCount}
                            />
                          </div>
                        ))}
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>

                <Carousel
                  className="d-block d-md-none"
                  activeIndex={mobileActiveIndex}
                  controls={false}
                  indicators={false}
                >
                  {data.map((recommendation) => (
                    <Carousel.Item>
                      <div
                        key={recommendation.id}
                        className="d-flex gap-2 flex-nowrap overflow-hidden"
                      >
                        <div key={recommendation.id}>
                          <ListingItem
                            {...recommendation}
                            photoHeight="150px"
                            onClick={onRecommendationClick}
                            clickCount={clickCount}
                            setClickCount={setClickCount}
                          />
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel> */}

                {/* <div className="row g-3 d-none d-md-flex flex-md-col">
                {data.map((recommendation) => (
                  <div className="col-6 col-md-4" key={recommendation.id}>
                    <ListingItem
                      {...recommendation}
                      photoHeight="150px"
                      onClick={onRecommendationClick}
                      clickCount={clickCount}
                      setClickCount={setClickCount}
                    />
                  </div>
                ))}
              </div> */}
              </div>
            ) : (
              <NoRecommendation />
            )}
          </div>

          <div className="d-flex d-md-none mx-auto gap-2">
            {data.map((_, index) => (
              <DotSlider
                isActive={mobileActiveIndex === index}
                onClick={() => {
                  setMobileActiveIndex(index);
                  ref?.current?.slickGoTo(index);
                }}
              />
            ))}
          </div>

          <Link href="/listing">
            <Button
              variant="outline-primary"
              className="rounded-5 border-2"
              style={{ fontWeight: 500 }}
            >
              Lihat Semua &gt;&gt;
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserBaseRecommendation;

const DotSlider = ({ isActive, onClick }) => {
  return (
    <div
      className={`${
        isActive ? "bg-primary" : "bg-secondary"
      } cursor-pointer rounded-circle`}
      style={{ width: "15px", height: "15px" }}
      onClick={onClick}
    ></div>
  );
};
