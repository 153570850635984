import React, { useRef } from "react";
import HeaderSearch from "../../common/HeaderSearch";
import Image from "next/image";
import SearchListingBar from "../../common/SearchListingBar";

const HeroSection = () => {
  const inputEl = useRef(null);
  return (
    <div className="position-relative" style={{ height: "803px" }}>
      <Image
        src="/assets/hero-section-background.png"
        alt="hero section"
        layout="fill"
        objectFit="cover"
      />

      <div className="position-relative d-flex flex-column gap-2 h-100">
        <HeaderSearch isLanding />

        <div
          className="container d-flex flex-column align-items-center justify-content-center flex-grow-1"
          style={{ gap: "3.5rem" }}
        >
          <div className="d-flex flex-column gap-2 align-items-center">
            <p className="d-none d-md-block font-roboto fw-bold text-center text-secondary mb-0">
              RUMAHDEWI
            </p>
            <p className="d-md-none fs-5 font-roboto fw-bold text-center text-secondary mb-0">
              RUMAHDEWI
            </p>
            <h1 className="text-center">
              <span
                className="d-none d-md-block font-roboto fw-bolder text-primary"
                style={{ fontSize: "3.125rem" }}
              >
                CARI PROPERTI IDAMAN ANDA DISINI!
              </span>
              <span
                className="d-md-none font-roboto fw-bolder text-primary"
                style={{ fontSize: "1.875rem" }}
              >
                CARI PROPERTI IDAMAN ANDA DISINI!
              </span>
            </h1>
          </div>

          <div className="d-none d-md-block" style={{ width: "64.75rem" }}>
            <SearchListingBar isLanding inputEl={inputEl} />
          </div>

          <div className="d-md-none w-100">
            <SearchListingBar isLanding inputEl={inputEl} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
